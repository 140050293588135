import React from "react"
import Layout from "../components/layout/layout";
import styled from "styled-components"
import Welcome from "../components/welcome/welcome";
import CenteredTextBlock from "../components/centeredTextBlock/centeredTextBlock";
import Gallery from "../components/gallery/gallery";
import List from "../components/list/list";
import FeatureDescriptionBlock, {FeatureListBlock} from "../components/featureDescriptionBlock/featureDescriptionBlock";
import SEO from "../utils/SEO";


const HomeContent = styled.div`
  position: relative;
  line-height: 1.5;
  opacity: 1;
  margin: auto;
  word-wrap: break-word;

  @media screen and (max-width: 1099px) {
    width: 100%;
  }
`

export default function Home() {

  var featureDescriptions = [
    {
      "header": "Quickly Add Tasks", 
      "body": "From the smallest tasks to the passions that get you up in the morning, track and quickly add anything on your mind."
    }, 
    {
      "header": "Reminders and Due Dates ", 
      "body": "Notifications delivered right to your lock screen with flexible Date and Reminder settings, highly customizable, for scheduling that fits the way you work."
    }, 
    {
      "header": "Your Daily Briefing", 
      "body": "See all of your calendar events and task items for the current day in one place. Focus on the most relevant tasks and let everything else drift to the background; Meraki will resurface them when you need them."
    }, 
    {
      "header": "Sort", 
      "body": "Using the sort feature, Meraki helps you make mindful decisions about the tasks you have unscheduled. No more stale tasks that get lost in your lists."
    }, 
    {
      "header": "Organize", 
      "body": "Create categories and lists to help neatly group all your items."
    }
  ]

  var featureComponents = featureDescriptions.map((featureDescription) => 
    <FeatureDescriptionBlock header={featureDescription.header} body={featureDescription.body} />
  );

  featureComponents.push(
    <FeatureListBlock header="Coming Soon" listItems={["Cross Platform Sync (Desktop + Web Apps)", "Repeating Tasks", "Shared Lists"]} />
  )


  return (
    <Layout>
      <SEO title="Home" />
      <HomeContent>
        <Welcome />
        <List listItems={featureComponents}/>
        <CenteredTextBlock blockHeader="Minimalist and Elegant" blockText="Carefully designed interface and interactions makes Meraki the most beautiful To-Do list you've ever used"/>
        <Gallery />
      </HomeContent>
    </Layout>
  );
}
