import React from "react"
import styled from "styled-components"


const BlockHeader = styled.h2`
  font-weight: 200;
  font-size: 1.75rem;
  margin-bottom: 1rem;
`


const BlockText = styled.p`
  font-weight: 200;
  font-size: 1.1rem;
  margin: auto;
  margin-bottom: 2rem;
  width: 50%;

  @media screen and (max-width: 1099px) {
    width: 90%;
  }
`


export default function CenteredTextBlock(props) {
  return (
    <div style={{textAlign: 'center'}}>
      <BlockHeader>{props.blockHeader}</BlockHeader>
      <BlockText>{props.blockText}</BlockText>
    </div>
  );
}
