import React from "react"
import styled from "styled-components"

const UnorderedListItem = styled.li`
  display: inline;
  width: 30%;
  position: relative;
  margin: 1rem;
  margin-bottom: 4rem;
  height: auto;

  @media screen and (max-width: 900px) {
    width: 80%;
  }
`

const UnorderedListRow = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 0;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

export default function List(props) {
  const listItems = props.listItems.map((listItem) => 
    <UnorderedListItem>{listItem}</UnorderedListItem>
  );

  return (
    <UnorderedListRow>{listItems}</UnorderedListRow>
  );
}
