import React from "react"
import styled from "styled-components"

const FeatureDescriptionHeader = styled.h2`
  font-weight: 200;
  font-size: 1.75rem;
  margin-bottom: 0.3rem;
`

const FeatureDescriptionBody = styled.p`
  font-weight: 200;
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const FeatureDescriptionList = styled.ul`
  font-weight: 200;
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const FeatureDescriptionListItem = styled.li`
`

export default function FeatureDescriptionBlock(props) {
  return (
    <div>
      <FeatureDescriptionHeader>{props.header}</FeatureDescriptionHeader>
      <FeatureDescriptionBody>{props.body}</FeatureDescriptionBody>
    </div>
  );
}

export function FeatureListBlock(props) {
  const listItems = props.listItems.map((listItem) => 
    <FeatureDescriptionListItem>{listItem}</FeatureDescriptionListItem>
  );

  return (
    <div>
      <FeatureDescriptionHeader>{props.header}</FeatureDescriptionHeader>
      <FeatureDescriptionList>{listItems}</FeatureDescriptionList>
    </div>
  );
}
