import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import "../../style/highlight.css"

const WelcomeContainer = styled.div`
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;

  @media screen and (max-width: 1099px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

const WelcomeText = styled.h3`
  flex-grow: 1;
  margin: auto;
  margin-right: 5%;
  
  @media screen and (max-width: 1099px) {
    flex-grow: 0;
    text-align: center;
  }
`

const WelcomeHeading = styled.h1`
  font-weight: 100;
  color: rgb(83, 83, 88);
  margin-bottom: 2rem;
  font-size: 1.8rem;
`

const WelcomeMessage = styled.h2`
  margin: 0;
  font-weight: 200;
  color: rgb(83, 83, 88);
  font-size: 2.8rem;

  @media screen and (max-width: 1099px) {
    font-size: 2.3rem;
  }
`

const WelcomeMockupContainer = styled.div`
  width: 50rem;
  margin: auto;
  margin-left: 5%;

  @media screen and (max-width: 1099px) {
    flex-grow: 0;
    max-width: 40%;
    width: none;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }
`

const AppStoreLink = styled(Link)`
  margin-top: 1rem;
  display: inline-block;
  overflow: hidden;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  width: 150px;

  @media screen and (max-width: 1099px) {
    width: 200px;
  }
`

function WelcomeMockup() {
  return (
    <StaticQuery
      query={graphql`
        query MockupQuery {
          mockup: file(name: { eq: "mockup-home-main" }, extension: { eq: "png" }) {
            childImageSharp {
              fluid(maxWidth: 600, pngQuality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Img fluid={data.mockup.childImageSharp.fluid} alt="iphone mockup" />
      )}
    />
  )
}


export default function Welcome() {
  return (
    <WelcomeContainer>
      <WelcomeText>
        <WelcomeHeading>Welcome to Meraki,</WelcomeHeading>
        <WelcomeMessage>Do something with <span class="highlight">soul</span>, <span class="highlight">creativity</span>, and <span class="highlight">love</span>; put a piece of yourself into your work</WelcomeMessage>

        <AppStoreLink to="https://apps.apple.com/us/app/meraki-to-do-list/id1526181709">
          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1380153600&h=e45a09c054b0079f030d093100efe8be" alt="Download on the App Store" style={{borderTopLeftRadius: '13px', borderTopRightRadius: '13px', borderBottomRightRadius: '13px', borderBottomLeftRadius: '13px', width: '250px', height: '83px'}}/>
        </AppStoreLink>
      </WelcomeText>
      <WelcomeMockupContainer><WelcomeMockup /></WelcomeMockupContainer>
    </WelcomeContainer>
  );
}
