import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"

const GalleryWall = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 1099px) {
    width: 100%;
  }

  .hidden {
    @media screen and (max-width: 1099px) {
      display: none;
    }
  }
`

const GalleryImage = styled(Img)`
  width: 30%;

  @media screen and (max-width: 1099px) {
    width: 50%;
    margin: auto;
  }
`

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 500, maxHeight: 500, , pngQuality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default function Gallery() {
  return (
    <StaticQuery
      query={graphql`
        query GalleryQuery {
          img1: file(name: { eq: "meraki-scene-1" }, extension: { eq: "png" }) {
            ...squareImage
          }
          img2: file(name: { eq: "meraki-scene-2" }, extension: { eq: "jpg" }) {
            ...squareImage
          }
          img3: file(name: { eq: "meraki-scene-3" }, extension: { eq: "jpg" }) {
            ...squareImage
          }
        }
      `}
      render={data => (
        <Link to="https://www.instagram.com/meraki.app/">
          <GalleryWall>
            <GalleryImage fluid={data.img1.childImageSharp.fluid} />
            <GalleryImage fluid={data.img2.childImageSharp.fluid} />
            <GalleryImage fluid={data.img3.childImageSharp.fluid} />
          </GalleryWall>
        </Link>
      )}
    />
  )
}
